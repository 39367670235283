import React from 'react';
import './App.css';

function App() {
  return (
    <>
    <div className="content wrapper topSection styled">
      <div className="header">
        <div className="wrapper"></div>
      </div>
      <h2>Site is under construction</h2>
      <strong>Stay tuned for something amazing</strong>
    </div>
    <div className="subscribe">
      <h3>Stay in touch</h3>
      <form method="post">
        <p>
          <input placeholder="Enter your e-mail" type="text" />
          <input type="button" value="Submit"/>
        </p>
      </form>
    </div>
    </>
  );
}

export default App;
